<template>
  <a-modal
    title="付款生产单详情"
    v-model="visible"
    width="1300px" 
    @ok="handleOk"
    @cancel="cancel"
  >
    <a-table
      :columns="columns"
      :dataSource="items"
      rowKey="id"
    ></a-table>
  </a-modal>
</template>

<script>
export default {
  props: ['visible','items'],
  model: { prop: 'visible', event: 'cancel' },
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 45,
          customRender: (value, item, index) => {
            return item.isTotal ? "合计" : index + 1;
          },
        },
        {
          title: "编号",
          dataIndex: "number",
          key: "number",
        },
        {
          title: "下单用户",
          dataIndex: "bm_user_name",
          key: "bm_user_name",
          // width:'60px'
        },
        {
          title: "状态",
          dataIndex: "statues",
          key: "statues",
        },
        {
          title: "产品名称",
          dataIndex: "product_name",
          key: "product_name",
        },
        {
          title: "单价",
          dataIndex: "sale_price",
          key: "sale_price",
        },
        {
          title: "订购数量",
          dataIndex: "quantity",
          key: "quantity",
        },
        {
          title: "实际发货",
          dataIndex: "use_quantity",
          key: "use_quantity",
          customRender: (text, record) => {
            const isMismatch = record.quantity !== record.use_quantity;
            const cellClass = isMismatch ? "highlight-cell" : "";
            const content = record.isTotal ? record.totalQuantity : text;
            return {
              children: <div class={cellClass}>{content}</div>,
              attrs: {},
            };
          },
        },
        {
          title: "运费",
          dataIndex: "express_cost",
          key: "express_cost",
          customRender: (text, record) => {
            if (text == 0) {
              return null;
            } else {
              return text;
            }
          },
        },
        {
          title: "包装费",
          dataIndex: "package_cost",
          key: "package_cost",
          customRender: (text, record) => {
            if (text == 0) {
              return null;
            } else {
              return text;
            }
          },
        },
        {
          title: "其他费用",
          dataIndex: "other_amount",
          key: "other_amount",
          customRender: (text, record) => {
            if (text == 0) {
              return null;
            } else {
              return text;
            }
          },
        },
        {
          title: "产品金额",
          dataIndex: "total_amount",
          key: "total_amount",
          customRender: (value, item) => {
            if (item.isTotal) return item.totalAmount;
            return item.total_amount;
          },
        },
        {
          title: "总金额",
          dataIndex: "order_amount",
          key: "order_amount",
          customRender: (value, item) => {
            if (item.isTotal) return item.totalOrder;
            return item.order_amount;
          },
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
          key: "update_time",
          // width:'110px'
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          key: "create_time",
        },
        {
          title: "用户备注",
          dataIndex: "remark",
          key: "remark",
        },
      ],
    };
  },
  methods: {
    cancel() {
      console.log('cancel')
        this.$emit('cancel', false);
       
      },
  }
};
</script>
